import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class KuspitAccountLinkingAccountWithoutContractViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.contract-savings.kuspit-account-linking.account_without_contract';

  show_contract_savings_modal = false;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  goToDashboard = () => {
    window.location.href = '/';
  }

  goToOnBoardingKuspit = () => {
    this.show_contract_savings_modal = true;
  }
}
