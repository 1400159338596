
























































import { Component, PropSync, Vue } from 'vue-property-decorator';

import KuspitAccountLinkingAccountWithoutContractViewModel
  from '@/vue-app/view-models/components/contract-savings/kuspit-account-linking/kuspit-account-linking-account-without-contract-view-model';

@Component({
  name: 'KuspitAccountLinkingAccountWithoutContract',
  components: {
    ContractSavings: () => import('@/vue-app/components/ContractSavings.vue'),
  },
})
export default class KuspitAccountLinkingAccountWithoutContract extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  goToOnBoardingKuspit() {
    this.synced_is_open = false;
    this.account_without_contract_view_model.goToOnBoardingKuspit();
  }

  account_without_contract_view_model = Vue.observable(
    new KuspitAccountLinkingAccountWithoutContractViewModel(),
  );
}
